<template>
  <b-sidebar
    id="script-group-sidebar-form"
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetEducatorData"
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('settings.educators.add-new') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <rc-overlay
        :show="processing"
        no-fade
        variant="white"
      >

        <!-- BODY -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetEducatorData"
          >
            <!-- First Name -->
            <validation-provider
              #default="validationContext"
              :name="$t('settings.educators.label.firstName')"
              rules="required"
            >
              <b-form-group
                :label="$t('settings.educators.label.firstName')"
                label-for="educator-first-name"
              >
                <b-form-input
                  id="educator-first-name"
                  v-model="educator.firstName"
                  autofocus
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Last Name -->
            <validation-provider
              #default="validationContext"
              :name="$t('settings.educators.label.lastName')"
              rules="required"
            >
              <b-form-group
                :label="$t('settings.educators.label.lastName')"
                label-for="educator-last-name"
              >
                <b-form-input
                  id="educator-last-name"
                  v-model="educator.lastName"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              :name="$t('settings.educators.label.email')"
              rules="required|email"
            >
              <b-form-group
                :label="$t('settings.educators.label.email')"
                label-for="educator-email"
              >
                <b-form-input
                  id="educator-email"
                  v-model="educator.email"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Phone -->
            <validation-provider
              #default="validationContext"
              :name="$t('settings.educators.label.phone')"
              rules="required"
            >
              <b-form-group
                :label="$t('settings.educators.label.phone')"
                label-for="educator-phone"
              >
                <vue-tel-input
                  v-model="educator.phoneNumber"
                  mode="international"
                  :dropdown-options="{ showFlags: true, showDialCodeInSelection: false, showSearchBox: true }"
                  :input-options="{ placeholder: '', showDialCode: false }"
                  :valid-characters-only="true"
                  @validate="handlePhoneNumberValidation"
                />

                <small
                  v-if="validationContext.errors.length"
                  class="text-danger"
                >{{ validationContext.errors[0] }}</small>
                <small
                  v-else-if="!phoneNumberValid"
                  class="text-danger"
                >{{ $t('validations.messages.phone-number-invalid') }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Consent to charging an additional fee -->
            <validation-provider
              #default="validationContext"
              name="additional-fee-consent"
              :rules="{ required: { allowFalse: false }}"
            >
              <b-form-checkbox
                id="additional-fee-consent"
                v-model="additionalFeeConsent"
                name="additional-fee-consent"
              >
                {{ $t('settings.educators.label.additional-fee-consent') }}
              </b-form-checkbox>
              <small
                v-if="validationContext.errors.length"
                class="text-danger"
              >
                {{ $t('shared.agree-is-required') }}
              </small>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ educator.id ? $t('shared.btn.update') : $t('shared.btn.add') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('shared.btn.cancel') }}
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </rc-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import router from '@/router'
import { parseRequestError } from '@/helpers/helpers'

export default {
  components: {
    RcOverlay,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isActive',
    event: 'update:is-script-group-form-sidebar-active',
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  setup(props, { emit }) {
    const processing = ref(false)
    const educator = ref({})
    const additionalFeeConsent = ref(false)
    const resetEducatorData = () => {
      educator.value = {}
    }

    const phoneNumberValid = ref(false)
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      resetObserver,
    } = formValidation(props.resetEducatorData)

    setTimeout(() => {
      resetObserver()
    }, 1000)
    const onSubmit = () => {
      processing.value = true
      store.dispatch('organization/addOrganizationUser', educator.value)
        .then(response => {
          window.toast.notify.success(i18n.t('settings.educators.toast.added'))

          emit('update:is-active', false)

          router.push({
            name: 'organization-settings-educator-view',
            params: {
              id: response.data.id,
            },
          })
        })
        .catch(parseRequestError)
        .finally(() => {
          processing.value = false
        })
    }

    const handlePhoneNumberValidation = phoneObject => {
      phoneNumberValid.value = phoneObject.valid === undefined || phoneObject.valid
    }

    return {
      processing,
      educator,
      additionalFeeConsent,
      phoneNumberValid,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      resetEducatorData,
      handlePhoneNumberValidation,
    }
  },
}
</script>
