import { ref, computed, nextTick } from '@vue/composition-api'

import store from '@/store'

export default () => {
  const refScriptListTable = ref(null)

  const loading = ref(false)
  const perPage = ref(10)
  const items = ref(0)
  const totalItems = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]

  const isAddEducatorFormActive = ref(false)

  const list = ref([])
  const dataMeta = computed(() => ({
    from: perPage.value * (currentPage.value - 1) + (totalItems.value ? 1 : 0),
    to: perPage.value * (currentPage.value - 1) + items.value,
    of: totalItems.value,
  }))

  const fetchData = () => {
    loading.value = true
    nextTick(() => {
      store.dispatch('organization/getOrganizationUsersPaginated', {
        params: {
          limit: perPage.value,
          offset: (currentPage.value - 1) * perPage.value,
        },
      })
        .then(res => {
          list.value = res.data.items
          items.value = res.data.filtered
          totalItems.value = res.data.total
        })
        .finally(() => {
          loading.value = false
        })
    })
  }

  fetchData()

  return {
    loading,
    perPage,
    currentPage,
    totalItems,
    dataMeta,
    perPageOptions,

    isAddEducatorFormActive,

    refScriptListTable,
    fetchData,
    list,
  }
}
