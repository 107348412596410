<template>
  <div>

    <new-educator-sidebar-form
      :is-active.sync="isAddEducatorFormActive"
      @refetch-data="fetchData"
    />

    <b-row>
      <b-col
        cols="12"
        xl="10"
        offset-xl="1"
      >

        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >

          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                cols="12"
                md="8"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>{{ $t('shared.show') }}</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                  @input="fetchData"
                />
                <label>{{ $t('shared.entries') }}</label>
              </b-col>

              <!-- Search -->
              <b-col
                cols="12"
                md="4"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-if="false"
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                  <b-button
                    variant="primary"
                    @click="isAddEducatorFormActive = true"
                  >
                    <span class="text-nowrap">{{ $t('settings.educators.btn.add') }}</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

          </div>

          <rc-overlay :show="loading">
            <b-table
              :key="`${currentPage}-meeting-list`"
              ref="refEducatorListTable"
              primary-key="id"
              responsive
              hover
              :items="list"
              :fields="tableColumns"
              :per-page="perPage"
              class="position-relative"
              show-empty
              :empty-text="loading ? '' : $t('shared.no-data')"
              @sort-changed="fetchData"
              @row-clicked="item => $router.push({name: 'organization-settings-educator-view', params: {id: item.id}})"
            >

              <!-- Column: Service -->
              <!--              <template #cell(service)="data">-->
              <!--                <b-link-->
              <!--                  variant="primary"-->
              <!--                  :to="{name: 'customer-meeting-view', params: {id: data.item.id}}"-->
              <!--                >-->
              <!--                  <strong-->
              <!--                    v-if="data.item.subject"-->
              <!--                    class="mr-1"-->
              <!--                  >-->
              <!--                    {{ data.item.subject }}-->
              <!--                  </strong>-->

              <!--                  <span>-->
              <!--                    <feather-icon-->
              <!--                      icon="ChevronsRightIcon"-->
              <!--                      class="mt-n25"-->
              <!--                    />-->
              <!--                    {{ data.item.service.name }}-->
              <!--                  </span>-->
              <!--                </b-link>-->
              <!--              </template>-->

              <!--              &lt;!&ndash; Column: Time &ndash;&gt;-->
              <!--              <template #cell(startAt)="data">-->
              <!--                <div class="text-right">-->
              <!--                  <span class="text-secondary">-->
              <!--                    {{ transformDateToHumanReadablePretty(data.item.startAt) }}-->
              <!--                  </span>-->
              <!--                  <strong class="text-nowrap">-->
              <!--                    {{ transformTimeToHumanReadablePretty(data.item.startAt) }}-->
              <!--                    - {{ transformTimeToHumanReadablePretty(data.item.endAt) }}-->
              <!--                  </strong>-->
              <!--                </div>-->
              <!--              </template>-->

              <!--              &lt;!&ndash; Column: Educator &ndash;&gt;-->
              <!--              <template #cell(educator)="data">-->
              <!--                <div class="text-right">-->
              <!--                  <span class="text-secondary">-->
              <!--                    {{ data.item.calendar.owner.lastName }} {{ data.item.calendar.owner.firstName }}-->
              <!--                  </span>-->
              <!--                </div>-->
              <!--              </template>-->

              <!--              &lt;!&ndash; Column: Payment status &ndash;&gt;-->
              <!--              <template #cell(payment)="data">-->
              <!--                <div-->
              <!--                  v-if="getPaymentStatus(data.item).total"-->
              <!--                  class="text-right"-->
              <!--                >-->
              <!--                  <span-->
              <!--                    v-if="getPaymentStatus(data.item).paid === getPaymentStatus(data.item).total "-->
              <!--                    v-b-tooltip.hover.v-success-->
              <!--                    class="font-weight-bold text-success"-->
              <!--                    :title="$t('meeting.attendees.status-paid')"-->
              <!--                  >-->
              <!--                    {{ getPaymentStatus(data.item).total }} zł-->
              <!--                  </span>-->
              <!--                  <span-->
              <!--                    v-else-if="getPaymentStatus(data.item).paid === 0"-->
              <!--                    v-b-tooltip.hover.v-danger-->
              <!--                    class="font-weight-bold text-danger"-->
              <!--                    :title="$t('meeting.attendees.status-not-paid')"-->
              <!--                  >-->
              <!--                    {{ getPaymentStatus(data.item).total }} zł-->
              <!--                  </span>-->
              <!--                  <span-->
              <!--                    v-else-->
              <!--                    v-b-tooltip.hover.v-warning-->
              <!--                    class="font-weight-bold text-warning"-->
              <!--                    :title="$t('meeting.attendees.status-partially-paid')"-->
              <!--                  >-->
              <!--                    {{ getPaymentStatus(data.item).paid }} / {{ getPaymentStatus(data.item).total }} zł-->
              <!--                  </span>-->
              <!--                </div>-->
              <!--              </template>-->

              <!--              &lt;!&ndash; Column: Actions &ndash;&gt;-->
              <!--              <template #cell(actions)="data">-->
              <!--                <b-button-->
              <!--                  variant="flat-primary"-->
              <!--                  :to="{name: 'organization-script-edit', params: {id: data.item.id}}"-->
              <!--                >-->
              <!--                  <feather-icon icon="EditIcon" />-->
              <!--                </b-button>-->
              <!--              </template>-->

            </b-table>
          </rc-overlay>

          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">{{ $t('shared.pagination-summary', dataMeta) }}</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-if="totalItems > perPage"
                  v-model="currentPage"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  @change="fetchData"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import listSetup from '@/views/organization/settings/educators/listSetup'
import NewEducatorSidebarForm from '@/views/organization/settings/educators/NewEducatorSidebarForm.vue'

export default {
  components: {
    NewEducatorSidebarForm,
    RcOverlay,

    vSelect,
  },
  directives: {
    // 'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // Table Handlers
      tableColumns: [
        { key: 'lastName', label: this.$t('settings.educators.label.lastName'), sortable: false },
        { key: 'firstName', label: this.$t('settings.educators.label.firstName'), sortable: false },
        { key: 'email', label: this.$t('settings.educators.label.email'), sortable: false },
        { key: 'phoneNumber', label: this.$t('settings.educators.label.phone'), sortable: false },
        // { key: 'actions', label: this.$t('shared.actions'), sortable: false },
      ],
    }
  },

  setup: listSetup,
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
